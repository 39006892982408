import Vue from 'vue';
import './commonComponents';
import i18n from './i18n';
import App from './App.vue';
import router from './router';
import store from './store/store';
import AJAX, { cancelAJAX } from './lib/ajax';
import './lib/directives';
import './lib/filters';
import config from './config';
import events from './events';
import whiteLabel, { defaultWhiteLabel } from './whiteLabel';
import mixinVersion from './mixins/mixinVersion';
import { xorColor } from './lib/util';
import pkg from '../package.json';
import { init as InitSentry, Replay, BrowserTracing, vueRouterInstrumentation } from '@sentry/vue';
import { ExtraErrorData, CaptureConsole } from '@sentry/integrations';
import { ZoneException } from './lib/zones/zoneException';

if (/^\d{1,3}(\.\d{1,3}){3}$/.test(window.location.hostname) && process.env.NODE_ENV === 'production') window.location.href = 'https://www.google.com';
else
{
  if (process.env.NODE_ENV === 'production')
  {
    InitSentry({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      tunnel: process.env.BASE_URL + 'test/errors.php',
      release: pkg.version,
      sendDefaultPii: true,
      Vue,
      attachStacktrace: true,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Replay({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
          networkDetailAllowUrls: ['/api/'],
        }),
        /*
        new SentryRRWeb({
          // default is empty
          checkoutEveryNth: 150,
          // default is 5 minutes
          //checkoutEveryNms: 15 * 60 * 1000,
          // on by default
          maskAllInputs: false,
          errorsOnly: true,
        }),*/
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(router),
        }),
        new ExtraErrorData(),
        new CaptureConsole({
          levels: ['error']
        }),
      ],
      denyUrls:
      [
        // Chrome extensions
        /extensions\//i,
        /^safari-extension:\/\//i,
        /^safari-web-extension:\/\//i,
        /^moz-extension:\/\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        /moz-extension/i,
        /intercomcdn\.com/,
        /widget-assets\/js/,
      ],
      ignoreErrors:
      [
        '@webkit-masked-url',
        'debugger eval',
        'Not implemented on this platform',
        'Failed to fetch',
        'Bin profiling',
        'DF completed after',
        'ChunkLoadError',
        'Loading CSS chunk',
        'Base.S3Writer',
        'Base.Main.GenericEvents',
        'Base.Message.Init',
        'Base.Events.PostMessageHandler',
      ],
      beforeSend: (event, hint) =>
      {
        const error = hint.originalException;
        if (error && error.message && error.message.match(/Device profiling did not complete in|Time boxed event exceeded timeout after/)) return null;
        if (error instanceof ZoneException) return null;
        if (window.isp)
        {
          const root = window.isp;
          event.user = {
            username: ((root.user || {}).username || '') + ' (' + ((root.session || {}).accID || '') + ')',
            email: (((root.account || {}).contact || {}).email || ''),
            company: (((root.account || {}).contact || {}).firstname || '') +
              ' ' +
              (((root.account || {}).contact || {}).lastname || '') +
              ' - ' +
              (((root.account || {}).contact || {}).organization || ''),
            screen: `${window.screen.width} x ${window.screen.height}`,
          };
        }
        return event;
      },
      beforeBreadcrumb: (breadcrumb, hint) =>
      {
        if (breadcrumb.category === 'xhr' && hint)
        {
          const data = {
            requestBody: hint.xhr.__sentry_xhr__.body,
            response: hint.xhr.response,
            responseUrl: hint.xhr.responseURL
          };
          return {
            ...breadcrumb,
            data
          };
        }
        return breadcrumb;
      }
    });
  }
  else
  {
    Vue.config.errorHandler = (err, vm, info) =>
    {
      // err: error trace
      // vm: component in which error occurred
      // info: Vue specific error information such as lifecycle hooks, events etc.
      console.error(vm.$options._componentTag + ': ' + info, err);
      if (window.isp) window.isp.showFailed(vm.$options._componentTag + '\n' + info + '\n' + err.message + '\n' + err.stack);
    };

    window.onerror = function onGlobalError(message, source, lineno, colno, error)
    {
      alert('Error at ' + lineno + ':' + colno + ' in "' + source + '"\n' + message + '\n' + error);
    };
  }

  Vue.config.devtools = window.location.hostname !== 'cp.namesrs.com';
  Vue.config.performance = false; // process.env.NODE_ENV !== 'production';
  Vue.config.productionTip = false;
  Vue.prototype.$ajax = AJAX;

  router.beforeEach((to, from, next) =>
  {
    cancelAJAX();
    next();
  });

  router.afterEach((to, from) =>
  {
    let title = to.meta.title;
    if (typeof title === 'function') title = title(to, window.isp);
    if (title)
    {
      // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
      if (to.meta.localize) title = i18n.t(title);
      document.title = title;
    }
    else document.title = (((window.isp || {}).whiteLabel || {}).globals || {}).brandName || ((window.isp || {}).brandInfo || {}).brandName || 'CP8';
    /* ticket 5315
    if (to.path !== '/')
    {
      const data = {
        t: 'pageview',
        cd: to.name || to.fullPath,
      };
      if (window.isp && window.isp.isLogged) data.uid = (window.isp.user || {}).username || window.isp.accID;
      if (process.env.NODE_ENV === 'production' && window.gaSend) window.gaSend(data);
    }*/
  });

  // cancel outstanding AJAX requests - but only those made from the component that is being destroyed
  /*
  Vue.mixin({
    data ()
    {
      return {
        ajaxList: [],
        onDestroy: null,
      }
    },
    beforeMount ()
    {
      this.onDestroy = this.beforeDestroy;
      this.beforeDestroy = () =>
      {
        while (this.ajaxList.length > 0)
        {
          const req = this.ajaxList.pop();
          req.abort();
        }
        if (this.onDestroy) this.onDestroy();
      };
    },
    methods:
      {
        ajax (params)
        {
          params.ajaxList = this.ajaxList;
          this.ajaxList.push(this.$ajax(params));
        }
      }
  });
  */
  // window.isp/i18n are referenced in store/preferences.js
  window.i18n = i18n;
  window.isp = new Vue({
    name: 'RootVue',
    mixins: [mixinVersion],
    data:
    {
      session: {},
      account: {},
      user: {},
      permissions: {},
      xhrQueue: [],
      mustLogin: false,
      twoFactor: null, // on the 1st time = {qr: URL, secret: string} so you can add the account in the phone, once configured in the phone = just empty {}
      twoFactorNew: null, // when activating 2FA = {new: true} on login, {nextstep: "validate"} after providing phone, {new: true, secret: "...", qr: URL to Google, nextstep: "validate2step"} when showing the QR
      suspended: false, // eslint-disable-line vue/no-unused-properties
      msg_text: '', // eslint-disable-line vue/no-unused-properties
      msg_type: 'error', // eslint-disable-line vue/no-unused-properties
      msg_timeout: 1000, // eslint-disable-line vue/no-unused-properties
      spin: 0,
      spinUser: 0,
      spinFactor: 0,
      spinBasket: 0, // eslint-disable-line vue/no-unused-properties
      spinExport: 0, // eslint-disable-line vue/no-unused-properties
      refresh: 1, // eslint-disable-line vue/no-unused-properties
      showErrorModal: false,
      errorModalData: null,
      userSnapLoaded: false,
      dnsValidating: false, // eslint-disable-line vue/no-unused-properties
      whiteLabel: defaultWhiteLabel, // eslint-disable-line vue/no-unused-properties
    },
    computed:
      {
        locale()
        {
          return this.$i18n.messages[this.$i18n.locale] ? this.$i18n.locale : 'en';
        },
        languages() // eslint-disable-line vue/no-unused-properties
        {
          return Object.keys(this.$i18n.messages);
        },
        isLogged() // eslint-disable-line vue/no-unused-properties
        {
          return this.session && !!this.session.accID && !this.mustLogin;
        },
        cfg() // eslint-disable-line vue/no-unused-properties
        {
          return config;
        },
        appVersion() // eslint-disable-line vue/no-unused-properties
        {
          const buildTime = new Date(process.env.BUILD_TIME);
          return (this.appISP ? pkg.version.replace(/^8/, '9') : pkg.version) + ' (' + buildTime.toLocaleString('en', {
            year: 'numeric',
            day: 'numeric',
            month: 'short',
            hour: 'numeric',
            minute: 'numeric',
          }) + ')';
        },
        appISP()
        {
          return window.location.hostname.split('.').slice(-3).join('.') === 'cp.nameisp.com';
        },
        /**
         * @public
         * @returns {boolean}
         */
        appSRS()
        {
          return window.location.hostname.split('.').slice(-3).join('.') === 'cp.namesrs.com';
        },
        ourApp() // eslint-disable-line vue/no-unused-properties
        {
          const parts = window.location.hostname.split('.').reverse();
          return process.env.NODE_ENV === 'development' || (parts[0] === 'com' && ['namesrs', 'nameisp'].includes(parts[1]) && parts[2] === 'cp');
        },
        isDEV() // eslint-disable-line vue/no-unused-properties
        {
          return process.env.NODE_ENV === 'development' || (this.ourApp && window.location.hostname.split('.')[0] === 'dev');
        },
        backendURL() // eslint-disable-line vue/no-unused-properties
        {
          return process.env.VUE_APP_API_URL;
        },
        accID() // eslint-disable-line vue/no-unused-properties
        {
          return +(this.session || {}).accID || 0;
        },
        routeNamesMap() // eslint-disable-line vue/no-unused-properties
        {
          const result = {};
          this.mapRoutes(result, this.$router.options.routes);
          return result;
        },
        brandInfo() // eslint-disable-line vue/no-unused-properties
        {
          let domain = window.location.hostname.split('.').slice(-2).join('.').toLowerCase();
          if (domain === 'localhost' || /^\d{1,3}(\.\d{1,3}){3}$/.test(domain)) domain = 'namesrs.com';
          return config.brand[domain] || {
            homepageURL: window.location.hostname,
            blogURL: 'https://news.namesrs.com',
            brandName: domain,
          };
        },
        brandLinks()
        {
          let domain = window.location.hostname.split('.').slice(-2).join('.').toLowerCase();
          if (domain === 'localhost' || /^\d{1,3}(\.\d{1,3}){3}$/.test(domain)) domain = 'namesrs.com';
          return config.links[domain] || config.links['namesrs.com'];
        }
      },
    created()
    {
      events.$on('show-failed', this.showFailed);
      events.$on('ajax-error', this.ajaxError);
      this.$on('logout', this.doLogout);
      if (this.ourApp) this.whiteLabel = whiteLabel;
      // apply the CSS variables from the theme
      // https://css-tricks.com/switch-font-color-for-different-backgrounds-with-css/
      const style = document.documentElement.style;
      const theme = whiteLabel[this.appISP ? 'themeISP' : 'theme'];
      for (const key in theme)
      {
        style.setProperty('--' + key, theme[key]);
      }
      const contrast = whiteLabel.contrast;
      for (const key in contrast)
      {
        style.setProperty('--' + key, xorColor(theme[contrast[key]]));
      }
      // init UserSnap - our self-hosted version for dev.cp.namesrs.com and the original one for cp.namesrs.com
      let snapURL, newScript, nonce;
      if (this.isDEV)
      {
        // load UserSnap
        snapURL = 'https://usersnap.namesrs.com/widget/loader.js';
        window._usersnapconfig = {
          apiKey: '19b40aa4-ff28-4c02-8d31-246dab4ea77f',
          consoleRecorder: true,
          title: true,
          titleRequired: true,
          //"titlePlaceholder": "Your title here",
          //"titleValue": "Predefined title",
          label: false,
          labelRequired: false,
          //"labelPlaceholder": 'Feedback',
          labels: ['IVO'], // labels must NOT contain comma
          labelAllowCreate: false,
          labelMultiSelect: true,
          assignee: false,
          assigneeRequired: false,
          emailBox: true,
          emailRequired: false,
          commentBox: true,
          commentRequired: true,
          //"addinfo": {field: value, field_2: value_2}, // some additional info - e.g. currently logged-in user
          usecase: 'custom',
          tools: ['highlight',
            'pen',
            'note',
            'blackout',
            'arrow'],
          theme: 'redmond',
          shortcut: false,
          btnText: 'Report Bug',
          halign: 'left',
          valign: 'middle',
          hideTour: true,
          lang: 'en',
          hostconfig:
            {
              cdn: '//cdn.usersnap.com',
              api: 'https://usersnap.namesrs.com',
              homepage: 'https://usersnap.com'
            },
          beforeSend: this.snapBeforeSend,
        };
      }
      else if (this.ourApp) // ticket #5409
      {
        snapURL = process.env.VUE_APP_USERSNAP_URL;
        nonce = document.querySelector('meta[name="snap"]').getAttribute('content');
        snapURL += '?onload=onUsersnapCXLoad&n=' + nonce;
        window.onUsersnapCXLoad = (api) =>
        {
          api.init({
            /*
            consoleRecorder: true,
            fields:
              {
                label:
                  {
                    label: 'Label',
                    initialValue: 'IVO',
                    required: true,
                    hidden: false
                  },
                assignee:
                  {
                    label: 'Assignee',
                    initialValue: 'IVO GELOV',
                    required: false,
                    hidden: true
                  }
              },
             */
          }).then(() =>
          {
            this.userSnapLoaded = true;
          });
          api.on('open', this.customUserSnap);
          api.on('close', this.closeUserSnap);
          window.Usersnap = api;
        };
      }
      else snapURL = '';
      if (snapURL)
      {
        newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.async = true;
        newScript.src = snapURL;
        if (nonce) newScript.nonce = nonce;
        document.head.appendChild(newScript);
      }

      // init Intercom chat
      newScript = document.createElement('script');
      newScript.type = 'text/javascript';
      newScript.async = true;
      newScript.src = 'https://widget.intercom.io/widget/' + config.intercom;
      newScript.onload = () =>
      {
        setTimeout(() =>
        {
          this.startIntercom();
        }, 250);
      };
      document.head.appendChild(newScript);
      /*
      if (process.env.NODE_ENV === 'production')
      {
        document.addEventListener('securitypolicyviolation', this.cspViolation);
      }
      */
      this.firstVersionCheck();
    },
    beforeDestroy()
    {
      events.$off('show-failed', this.showFailed);
      events.$off('ajax-error', this.ajaxError);
      this.$off('logout', this.doLogout);
      /*
      if (process.env.NODE_ENV === 'production')
      {
        document.removeEventListener('securitypolicyviolation', this.cspViolation);
      }
      */
    },
    methods:
      {
        login(ajaxReq, params, twoFactor)
        {
          if (twoFactor)
          {
            if (twoFactor.new || Object.keys(twoFactor).length > 0) this.twoFactorNew = twoFactor;
            else this.twoFactor = twoFactor;
          }
          else if (!this.$route.meta.public)
          {
            this.mustLogin = true;
            this.xhrQueue.push({
              req: ajaxReq,
              params
            });
          }
          else this.cleanLoginData();
        },
        cleanLoginData()
        {
          this.$store.commit('setUsername', '');
          this.session = {};
          this.account = {};
          this.user = {};
          window.Intercom && window.Intercom('shutdown');
          this.$emit('logged-out');
        },
        /**
         * @public
         * @param code {String}
         * @param cb {Function}
         */
        sendFactor(code, cb)
        {
          const form = new FormData();
          form.append('code', code);
          if ((this.twoFactor || {}).secret) form.append('secret', this.twoFactor.secret);
          this.$ajax(
            {
              method: 'POST',
              url: '/api/authenticate/userlogin2step/',
              timeout: 9000,
              data: form,
              keep: true,
              okay: (data) =>
              {
                this.session = data.session;
                this.twoFactor = null;
                while (this.xhrQueue.length > 0)
                {
                  const { req, params } = this.xhrQueue.shift();
                  req.$perform(params);
                }
                this.getUserAccount(cb);
              },
              fail: (stat, error) =>
              {
                if (stat === 2204 || stat === 2202) this.$emit('two-factor-error', error);
                else this.showFailed(error.desc);
              },
              spinner: (show) =>
              {
                this.spinFactor += show ? 1 : this.spinFactor > 0 ? -1 : 0;
              }
            }
          );
        },
        /**
         * @public
         * @param username {String}
         * @param password {String}
         * @param cb {Function}
         */
        retry(username, password, cb)
        {
          const form = new FormData();
          form.append('user', username);
          form.append('password', password);
          this.$store.commit('setUsername', username);
          this.$ajax(
            {
              method: 'POST',
              url: '/api/authenticate/userlogin/',
              timeout: 9000,
              data: form,
              keep: true,
              okay: (data) =>
              {
                this.session = data.session;
                this.mustLogin = false;
                while (this.xhrQueue.length > 0)
                {
                  const { req, params } = this.xhrQueue.shift();
                  req.$perform(params);
                }
                this.getUserAccount(cb);
              },
              spinner: (show) =>
              {
                this.spinUser += show ? 1 : this.spinUser > 0 ? -1 : 0;
              }
            }
          );
        },
        getUserAccount(cb)
        {
          this.$ajax(
            {
              method: 'GET',
              url: '/api/account/getaccountdetails/',
              timeout: 8000,
              login: this.login,
              keep: true,
              okay: (data) =>
              {
                if (data.session && data.session.accID)
                {
                  this.session = data.session;
                  this.account = data.account;
                  this.$emit('session-account', data.account);
                }
                this.getCurrentUser(cb);
              },
              fail: (stat, error) =>
              {
                if (stat !== -1) this.showFailed(error);
              },
              spinner: (show) =>
              {
                this.spinUser += show ? 1 : this.spinUser > 0 ? -1 : 0;
              }
            }
          );
        },
        getCurrentUser(cb)
        {
          this.$ajax(
            {
              method: 'GET',
              url: '/api/user/getuserdetails/',
              timeout: 8000,
              keep: true,
              okay: (data) =>
              {
                if (data.session && data.session.accID)
                {
                  this.session = data.session;
                  this.user = data.user;
                  const contact = ((this.account || {}).contact || {});
                  if (window.SessionStack)
                  {
                    window.SessionStack.identify({
                      userId: data.session.usrID || data.session.accID,
                      email: contact.email,
                      displayName: `${contact.firstname} ${contact.lastname}`,
                      organization: contact.organization,
                    });
                    window.SessionStack.start();
                  }
                  // get permissions
                  this.$ajax(
                    {
                      method: 'GET',
                      url: '/api/user/permissions/?accid=' + this.accID + '&usrid=' + this.session.usrID,
                      timeout: 8000,
                      keep: true,
                      okay: (response) =>
                      {
                        this.permissions = Object.fromEntries(((response.permissions[0] || {}).permissions || []).map(val => [val, true]));
                        if (typeof cb === 'function') cb();
                      },
                      spinner: (show) =>
                      {
                        this.spinUser += show ? 1 : this.spinUser > 0 ? -1 : 0;
                      }
                    }
                  );
                }
              },
              fail: (stat, error) =>
              {
                if (stat !== 2200) this.showFailed(error);
                else this.mustLogin = true;
              },
              spinner: (show) =>
              {
                this.spinUser += show ? 1 : this.spinUser > 0 ? -1 : 0;
              }
            }
          );
        },
        doLogout()
        {
          this.$root.mustLogin = false;
          this.$root.twoFactor = null;
          this.$ajax(
            {
              method: 'GET',
              url: '/api/authenticate/logout/',
              timeout: 8000,
              keep: true,
              okay: () =>
              {
                this.cleanLoginData();
                if (this.$route.name !== 'home') this.$router.push('/').catch(() => true);
              },
              spinner: (show) =>
              {
                this.spinUser += show ? 1 : this.spinUser > 0 ? -1 : 0;
              }
            }
          );
        },
        /**
         * @public
         */
        setLangCurrency()
        {
          if (!this.$store.getters.getCurrency) this.$store.commit('setCurrency', this.account.currency || this.user.currency || 'SEK');
          if (!this.$store.getters.curLocale) this.$store.commit('setLocale', this.account.lang || this.user.language || 'en');
          this.$emit('basket', () =>
          {
            if (this.$route.name !== 'dashboard') this.$router.push({ name: 'dashboard' }).catch(() => true);
          });
          this.updateIntercom();
        },
        startIntercom()
        {
          window.intercomSettings = {
            app_id: config.intercom,
          };
          if (window.Intercom)
          {
            window.Intercom('boot', window.intercomSettings);
          }
        },
        updateIntercom()
        {
          const client = ((this.account || {}).contact || {});
          if (window.Intercom)
          {
            window.Intercom('update', {
              user_id: (this.session || {}).accID + ' (' + ((this.user || {}).username || '') + ')',
              name: `${client.firstname} ${client.lastname}`,
              email: client.email,
              phone: client.phone,
              company:
                {
                  company_id: client.contactid,
                  name: `${client.organization} (${client.orgnr})`,
                },
            });
          }
        },
        snapBeforeSend(obj)
        {
          // used by our self-hosted UserSnap in development mode
          const contact = (this.account || {}).contact || {};
          obj.addInfo = {
            username: ((this.user || {}).username || '') + ' (' + ((this.session || {}).accID || '') + ')',
            account: (contact.firstname || '') + ' ' + (contact.lastname || '') + ' - ' + (contact.organization || '') + ' (' + (contact.email || '') + ')',
          };
          return true;
        },
        customUserSnap(event)
        {
          console.timeEnd('userSnap');
          // used by original UserSnap in production mode
          const contact = (this.account || {}).contact || {};
          event.api.setValue('custom', {
            username: ((this.user || {}).username || '') + ' (' + ((this.session || {}).accID || '') + ')',
            email: (contact.email || ''),
            company: (contact.firstname || '') + ' ' + (contact.lastname || '') + ' - ' + (contact.organization || '')
          });
          event.api.setValue('user', {
            userId: (this.session || {}).accID || 0,
            email: contact.email || '',
          });
        },
        closeUserSnap(event)
        {
          event.api && event.api.hide(config.userSnapKey);
        },
        showFailed(msg)
        {
          this.$snotify.error(this.showMsg(msg || ''), this.$t('notifications.error'), {
            timeout: config.notifyTimeoutFail,
            closeOnClick: true,
          });
        },
        ajaxError(code, msg, data)
        {
          const errors = ((data || {}).output || {}).error;
          if (errors && errors.parameter && msg) msg += ' - parameter "' + errors.parameter + '"';
          this.$snotify.error(this.showMsg(msg || ''), this.$t('notifications.error'), {
            timeout: code > 0 ? config.notifyTimeoutFail : 0,
            closeOnClick: true, // !!(data || {}).input || code < 0,
            preventDuplicates: true,
            buttons: (data || {}).input
              ? [
                  {
                    text: this.$t('buttons.more_info'),
                    action: () =>
                    {
                      this.errorModalData = data;
                      this.showErrorModal = true;
                    }
                  }
                ]
              : []
          });
        },
        /**
         * @public
         * @param msg {String}
         */
        showSuccess(msg)
        {
          this.$snotify.success(this.showMsg(msg), this.$t('notifications.success'), {
            timeout: config.notifyTimeoutSuccess,
          });
        },
        showMsg(msg)
        {
          // \s\S is workaround for "." not matching on CRLF
          if (typeof msg === 'object') msg = msg.msg || msg.message || 'Unknown error';
          return msg.indexOf('<html') !== -1 ? msg.replace(/^[\s\S]+<body[^>]*>/i, '').replace(/<\/body>[\s\S]*$/i, '') : msg;
        },
        /**
         * @public
         * @param show {Boolean}
         */
        showSpinner(show)
        {
          if (show) this.spin++;
          else if (this.spin > 0) this.spin--;
        },
        /**
         * @public
         * @param name {String}
         * @returns {Array}
         */
        menu(name)
        {
          return this.$router.options.routes.find(item => item.name === name);
        },
        /**
         * @public
         * @param identifier {String}
         * @returns {String}
         */
        siteLink(identifier)
        {
          return this.brandLinks[identifier].replace('{LOCALE}', this.locale);
        },
        /*
        https://docs.sentry.io/product/security-policy-reporting/
        cspViolation (evt)
        {
          if (['eval', 'inline', 'gstatic.com'].includes(evt.blockedURI)) return; // inline comes from browser extension(s), no clue where eval comes from
          SentryScope(scope =>
          {
            SentryTags({
              CSP_blockedURI: evt.blockedURI,
              CSP_documentURI: evt.documentURI,
              CSP_violatedDirective: evt.violatedDirective,
              CSP_originalPolicy: evt.originalPolicy,
              CSP_sourceFile: evt.sourceFile,
              CSP_lineNumber: evt.lineNumber,
              CSP_colNumber: evt.columnNumber,
              CSP_sample: evt.sample,
            });
            captureMessage('Content Security Policy violated', 'error');
          });
        }
        */
        mapRoutes(obj, routes)
        {
          routes.forEach(item =>
          {
            obj[item.name] = item;
            if (item.children) this.mapRoutes(obj, item.children);
          });
        },
      },
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app');
}
