<template>
  <my-modal :value="!!$root.twoFactorNew" :escape="false" persistent>
    <form v-loading="loading > 0" style="min-width: 325px; max-width: 460px;" @submit.prevent="performStep">
      <my-card>
        <template slot="title">{{ $t('login.two_step') }}</template>
        <div class="pa-3">
          <!-- ask for phone -->
          <template v-if="nextStep === 'request'">
            <info-box>
              {{ $t('login.two_step_active_help') }}
            </info-box>
            <div class="flexbox flex-column align-center mt-4">
              <form-field :label="'* ' + $t('signup.country')">
                <my-icon slot="prepend" class="ml-2 mr-2">
                  <icon-globe />
                </my-icon>
                <select ref="country" v-model="countrycode" name="country" autocomplete="country" required>
                  <option v-for="opt in countryList" :key="opt.value" :value="opt.value">{{ opt.text }}</option>
                </select>
              </form-field>
              <form-field :label="'* ' + $t('signup.phone')">
                <template slot="prepend">
                  <my-icon class="ml-2">
                    <icon-phone />
                  </my-icon>
                  <span class="px-1">{{ phoneCode }}</span>
                </template>
                <input ref="phone" v-model.trim="phone" class="phone_code" name="phone" autocomplete="tel-national" minlength="7" maxlength="15" inputmode="numeric" pattern="[0-9]*" required>
              </form-field>
            </div>
          </template>
          <!-- ask for PIN -->
          <template v-else-if="nextStep === 'validate'">
            <div class="flexbox flex-column align-center">
              <info-box class="mb-4">
                {{ $t('login.two_step_sms_help') }}
              </info-box>
              <form-field>
                <div slot="prepend" class="pl-2 pr-2">{{ $t('login.two_step_code') }}</div>
                <input ref="pin" v-model.trim="pin" minlength="6" maxlength="6" pattern="[0-9]+" style="width: 100px;" required>
              </form-field>
              <div v-if="wrongPin" class="flex-center center error_color pt-2">
                <my-icon class="mr-2">
                  <icon-attention />
                </my-icon>
                {{ $t('login.wrong_pin') }}
              </div>
            </div>
          </template>
          <!-- show QR code -->
          <template v-else-if="nextStep === 'validate2step'">
            <info-box>
              <div>{{ $t('login.two_step_help') }}</div>
              <div align="center" class="mt-2 mb-2">
                <a :href="otpURL">
                  <qr-code :value="otpURL" dot="5" />
                </a>
              </div>
              <form-field style="color: #000;">
                <div slot="prepend" class="pl-2 pr-2">{{ $t('login.two_step_secret') }}</div>
                <input :value="secret" readonly>
              </form-field>
            </info-box>
            <div class="flexbox flex-column align-center mt-4">
              <form-field>
                <div slot="prepend" class="px-2">{{ $t('login.two_step_code') }}</div>
                <input ref="code" v-model.trim="code" minlength="6" maxlength="6" pattern="[0-9]+" style="width: 100px;" required>
              </form-field>
              <div v-if="factorFailed" class="center error_color pt-2">
                <my-icon class="mr-2">
                  <icon-attention />
                </my-icon>
                {{ $t('login.two_step_fail') }}
              </div>
            </div>
          </template>
        </div>
        <div slot="footer" class="pl-2 pr-2 pb-2 flexbox justify-center">
          <my-button class="primary" type="submit">{{ $t('buttons.submit') }}</my-button>
          <my-button class="secondary" @click="close">{{ $t('buttons.cancel') }}</my-button>
        </div>
      </my-card>
    </form>
  </my-modal>
</template>

<script>
import qrCode from '@/components/ui/QRtable';
import infoBox from '@/components/widget/InfoBox';
import iconAttention from '@/assets/img/icon/attention.svg';
import iconPhone from '@/assets/img/icon/phone.svg';
import iconGlobe from '@/assets/img/icon/globe.svg';
import { mapGetters, mapMutations } from 'vuex';
import { strCompare } from '@/lib/util';

export default
{
  name: 'TwoFactorActivate',
  components:
    {
      qrCode,
      infoBox,
      iconAttention,
      iconGlobe,
      iconPhone,
    },
  data()
  {
    return {
      nextStep: '',
      countrycode: null,
      phone: '',
      pin: '',
      secret: '',
      qrCode: '',
      code: '',
      loading: 0,
      wrongPin: false,
      factorFailed: false,
    };
  },
  computed:
    {
      ...mapGetters(['getCountries', 'getCountryMap']),
      otpURL()
      {
        const qr = this.qrCode;
        return qr ? (qr.indexOf('googleapis.com') !== -1 ? decodeURIComponent(qr.replace(/^.+&chl=otpauth/, 'otpauth')) : qr) : '';
      },
      countryList()
      {
        return this.getCountries.map(item =>
        {
          return {
            text: item.Country,
            value: item.ISO2,
          };
        }).sort((a, b) =>
        {
          return strCompare(a.text, b.text);
        });
      },
      fullPhone()
      {
        return `${this.phoneCode || '000'}.${this.phone || ''}`;
      },
      phoneCode()
      {
        // Western Sahara does not have a phone code
        return (this.getCountryMap[this.countrycode] || {}).Phoneprefix || '';
      }
    },
  watch:
    {
      '$root.twoFactorNew'(newVal, oldVal)
      {
        if (newVal && !oldVal)
        {
          this.nextStep = 'request';
          this.countrycode = null;
          this.phone = '';
          this.secret = '';
          this.qrCode = '';
          this.$nextTick(() =>
          {
            this.$refs.phone.focus();
          });
          this.geoIP();
        }
      },
    },
  created()
  {
    if (this.getCountries.length === 0)
    {
      this.$ajax(
        {
          method: 'GET',
          url: '/api/miscellaneous/getgeo/',
          keep: true,
          okay: (data) =>
          {
            this.setCountries(data.geo);
          },
        }
      );
    }
  },
  methods:
    {
      ...mapMutations(['setCountries']),
      geoIP()
      {
        // try to detect country from the IP
        this.loading++;
        fetch('https://ipgeolocation.abstractapi.com/v1/?api_key=' + this.$root.cfg.ip_geo)
          .then(response => response.json())
          .then(data =>
          {
            this.countrycode = data.country_code;
          }).catch(error =>
          {
            this.$root.showFailed(error.message || error);
          }).finally(() =>
          {
            this.loading += this.loading > 0 ? -1 : 0;
          });
      },
      performStep()
      {
        switch (this.nextStep)
        {
          case 'request':
            this.setPhone();
            break;
          case 'validate':
            this.sendPIN();
            break;
          case 'validate2step':
            this.sendQR();
            break;
        }
      },
      setPhone()
      {
        const data = new FormData();
        data.append('step', this.nextStep);
        data.append('phone', this.fullPhone);
        this.$ajax(
          {
            method: 'POST',
            url: '/api/user/activate2step/',
            data,
            okay: (result) =>
            {
              this.nextStep = result['2step'].nextstep;
              this.pin = '';
              this.$nextTick(() =>
              {
                this.$refs.pin.focus();
              });
            },
            fail: (stat, msg) =>
            {
              if (stat == 2302) this.$root.showFailed(this.$t('login.two_step_exists'));
              else this.$root.showFailed(msg);
            },
            spinner: (show) =>
            {
              this.loading += show ? 1 : this.loading > 0 ? -1 : 0;
            }
          }
        );
      },
      sendPIN()
      {
        this.wrongPin = false;
        const data = new FormData();
        data.append('step', this.nextStep);
        data.append('pincode', this.pin);
        this.$ajax(
          {
            method: 'POST',
            url: '/api/user/activate2step/',
            login: (xhr, params) => this.$root.showFailed(params.json.desc),
            data,
            okay: (result) =>
            {
              this.nextStep = result['2step'].nextstep;
              this.secret = result['2step'].secret;
              this.qrCode = result['2step'].qr;
              this.$nextTick(() =>
              {
                this.$refs.code.focus();
              });
            },
            fail: (stat, msg) =>
            {
              if (stat == 2200) this.wrongPin = true;
              else this.$root.showFailed(msg);
            },
            spinner: (show) =>
            {
              this.loading += show ? 1 : this.loading > 0 ? -1 : 0;
            }
          }
        );
      },
      sendQR()
      {
        this.factorFailed = false;
        const data = new FormData();
        data.append('step', this.nextStep);
        data.append('code', this.code);
        data.append('secret', this.secret);
        this.$ajax(
          {
            method: 'POST',
            url: '/api/user/activate2step/',
            data,
            okay: () =>
            {
              this.$root.twoFactorNew = null;
              this.$root.user['2step'] = true;
            },
            fail: (stat, msg) =>
            {
              if (stat == 2200) this.factorFailed = true;
              else this.$root.showFailed(msg);
            },
            spinner: (show) =>
            {
              this.loading += show ? 1 : this.loading > 0 ? -1 : 0;
            }
          }
        );
      },
      close()
      {
        this.$root.twoFactorNew = null;
        this.$root.$emit('logout');
      }
    }
};
</script>
