<template>
  <div class="application">
    <CustomHeader v-show="$root.isLogged && !['signUp','affiliate'].includes(($route || {}).name)" />
    <router-view :key="$root.refresh" :class="{must_login: $root.mustLogin}" />
    <CustomFooter />
    <!-- <new-version :show="$root.newVersionExists"/> -->
    <ajax-error v-model="$root.showErrorModal" :info="$root.errorModalData" />
    <!-- Non-interrupting Login for expired sessions -->
    <session-expired v-if="!atAffiliate" />
    <!-- Two-factor authentication -->
    <two-factor v-if="!atAffiliate" />
    <two-factor-new v-if="!atAffiliate" />
    <suspended-account />
    <user-feedback v-if="publicUserSnap" />
    <vue-snotify />
  </div>
</template>

<script>
import CustomHeader from '@/components/layout/header/MainHeader';
import CustomFooter from '@/components/layout/header/MainFooter';
import sessionExpired from './views/SessionExpired';
import twoFactor from './views/TwoFactor';
import twoFactorNew from './views/TwoFactorActivate';
import suspendedAccount from './views/SuspendedAccount.vue';
import userFeedback from '@/components/UserFeedback';
// import newVersion from '@/components/NewVersion'
import ajaxError from './views/AjaxError';
import { mapGetters, mapMutations } from 'vuex';
import { xorColor } from '@/lib/util';
import objectAssignDeep from '@/lib/objectAssignDeep';
import whiteLabelConfig, { defaultWhiteLabel } from '@/whiteLabel';
import events from '@/events';

export default
{
  name: 'App',
  components:
    {
      CustomHeader,
      CustomFooter,
      sessionExpired,
      twoFactor,
      twoFactorNew,
      suspendedAccount,
      ajaxError,
      userFeedback,
      // newVersion,
    },
  computed:
    {
      ...mapGetters(['curLocale']),
      publicUserSnap()
      {
        return !!process.env.VUE_APP_USERSNAP_URL && process.env.NODE_ENV === 'production';
      },
      atAffiliate()
      {
        return window.location.pathname.startsWith('/affiliate/');
      }
    },
  watch:
    {
      '$root.accID'(newVal, oldVal)
      {
        if (newVal && oldVal !== newVal) this.updateInfo();
      }
    },
  created()
  {
    // load the preferred or default locale
    const query = new URLSearchParams(window.location.search);
    let lang = query.get('lang');
    if (!lang) lang = this.curLocale;
    if (!lang) lang = ((navigator.languages && navigator.languages[0]) || '').substr(0, 2);
    if (lang) this.setLocale(this.$root.languages.includes(lang) ? lang : 'en');

    this.$root.$on('basket', this.loadBasket);
    this.$root.$on('basket-toast', this.toastBasket);
    this.$root.$on('session-account', this.enableWhiteLabel);
    // page was reloaded - check if we are already logged-in
    this.updateInfo();
    events.$on('new-list-size', this.storeListSize);
  },
  beforeDestroy()
  {
    this.$root.$off('basket', this.loadBasket);
    this.$root.$off('basket-toast', this.toastBasket);
    this.$root.$off('session-account', this.enableWhiteLabel);
    events.$off('new-list-size', this.storeListSize);
  },
  methods:
    {
      ...mapMutations([
        'setBasket',
        'setCurrency',
        'setLocale',
        'setCountUnread']),
      updateInfo()
      {
        if (!this.atAffiliate)
        {
          this.$root.getUserAccount(() =>
          {
            this.setCurrency(this.$root.user.currency || this.$root.account.currency || 'SEK');
            this.setLocale(this.$root.user.language || this.$root.account.lang || 'en');
            if (this.$route.name === 'home') this.$router.push({ name: 'dashboard' }).catch(() => false);
            this.$root.startIntercom();
          });
          this.loadBasket();
          this.countUnreadTickets();
        }
        // fetch whitelabel config
        if (!this.$root.ourApp)
        {
          this.$ajax({
            method: 'GET',
            url: '/api/whitelabel/fetch_whitelabel/?host=' + window.location.hostname,
            login: this.$root.login,
            keep: true,
            okay: (data) =>
            {
              let config;
              try
              {
                config = JSON.parse((data.content || {}).config || '{}');
              }
              catch (e)
              {}
              const newConfig = this.$root.whiteLabel = objectAssignDeep({}, defaultWhiteLabel, config);
              // apply config
              const style = document.documentElement.style;
              Object.entries(newConfig.theme).forEach(([key, val]) =>
              {
                if (key !== 'logo') style.setProperty('--' + key, val);
              });
              const contrast = whiteLabelConfig.contrast;
              for (const key in contrast)
              {
                style.setProperty('--' + key, xorColor(newConfig.theme[contrast[key]]));
              }
            },
            fail: (stat, error) =>
            {
              // simply ignore errors - the backend responds with 2500 when there are no white label values in the database
            },
          });
        }
      },
      loadBasket(cb)
      {
        this.$ajax(
          {
            method: 'GET',
            url: '/api/order/basket/',
            login: this.$root.login,
            keep: true,
            okay: (data) =>
            {
              this.setBasket(data.parameters);
              if (typeof cb == 'function') cb();
            },
            spinner: (show) =>
            {
              this.$root.spinBasket += show ? 1 : this.$root.spinBasket > 0 ? -1 : 0;
            }
          }
        );
      },
      countUnreadTickets()
      {
        this.$ajax(
          {
            method: 'GET',
            url: '/api/support/getunreadcount/',
            login: this.$root.login,
            keep: true,
            okay: (data) =>
            {
              this.setCountUnread(data.count || 0);
            },
            fail: false,
          }
        );
      },
      toastBasket()
      {
        this.$root.showSuccess(this.$t('checkout.toast_message'));
      },
      enableWhiteLabel(account)
      {
        const routeObj = this.$router.match('/account/whitelabel');
        if (routeObj)
        {
          routeObj.meta.menu = +account.whitelabel > 0;
        }
      },
      storeListSize(size)
      {
        // ticket #5504
        const data = new FormData();
        data.append('listsize', size);
        this.$ajax(
          {
            method: 'POST',
            url: '/api/user/setusersettings/',
            login: this.login,
            data,
            okay: () =>
            {
              this.$root.user.listsize = +size;
            }
          }
        );
      }
    }
};
</script>

<style lang="scss">
  *,
  *::before,
  *::after
  {
    box-sizing: border-box;
  }

  *:focus
  {
    outline: none;
  }

  html,
  body
  {
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    color: $text_color;
    overflow: hidden !important;
    min-height: 100%;
    height: 100%;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6
  {
    margin: 0;
  }

  p
  {
    margin: 8px 0;
    text-indent: 1em;
  }

  .application
  {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  svg
  {
    fill: currentColor;
  }

  main
  {
    overflow: auto;
    flex: 1 1 0;
    background-color: var(--bg_color, $bg_color);
  }

  hr
  {
    margin: 0;
  }

  a
  {
    color: var(--link_color, $link_color);
    text-decoration: none;
  }

  textarea
  {
    font-family: inherit;
  }

  .must_login
  {
    filter: blur(7px);
  }

  .spinner
  {
    z-index: 255; /* to stay on top of Vuetify dialogs */
    position: fixed;
    top: 50%;
    left: 50%;
    height: 100px;
    width: 100px;
    margin-left: -50px;   /* negative, half of width above */
    margin-top: -50px;   /* negative, half of height above */
    animation: spinloader 1.1s infinite linear;
  }

  .vue-loading
  {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    cursor: wait;
    /*
    background-color: rgba(230, 230, 230, 0.4);
    opacity: 0;
    transition: opacity 0.4s;
    */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .vue-loading > *
  {
    animation: spinloader 1.1s infinite linear;
  }

  @keyframes spinloader
  {
    0%
    {
      transform: rotate(0deg);
    }

    100%
    {
      transform: rotate(359deg);
    }
  }

  .main_screen
  {
    min-height: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;

    @media (max-width: 1024px)
    {
      max-width: calc(100% - 2 * 20px);
    }
  }

  .light_bg
  {
    background-color: $bg_color;
  }

  table.data_table .default_button
  {
    max-height: 30px;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
  }

  table.data_table:not(.dns_table) .color_button
  {
    padding: 4px 8px;
  }

  .sheet
  {
    background-color: $button_default_hover;
  }

  .popup_menu_item
  {
    cursor: pointer;
    padding: $margin_step $margin_step * 2;
  }

  .popup_menu_item:not([disabled]):hover
  {
    background-color: $button_default_hover;
  }

  .actions_border
  {
    border-top: 1px solid $section_panel_border;
  }

  .info_panel
  {
    background-color: $light_bg;
    border-radius: 4px;
    color: $text_color;
  }

  .info_panel.soft
  {
    background-color: lighten($light_bg, 4.25%);
  }

  .info_panel .i_icon.primary
  {
    color: $primary_color;
  }

  .main_screen .multiselect__option--highlight
  {
    background-color: var(--info_box_bg);
  }

  .intercom-namespace iframe.intercom-launcher-frame,
  .intercom-lightweight-app .intercom-launcher
  {
    right: 12px;
    bottom: 36px;
  }

  .intercom-namespace iframe.intercom-launcher-frame,
  .intercom-app > .intercom-messenger-frame,
  body > .intercom-lightweight-app
  {
    z-index: 58;
  }

  .radio,
  .checkbox
  {
    appearance: none;
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: var(--checkbox_size);
    width: var(--checkbox_size);
    flex: 0 0 var(--checkbox_size);
    cursor: pointer;
    display: inline-block;
  }

  .radio::before,
  .checkbox::before
  {
    box-sizing: border-box;
    position: absolute;
    content: '';
    display: inline-block;
    width: var(--checkbox_size);
    height: var(--checkbox_size);
    background-color: $bg_color;
    border: 1px solid var(--checkbox_border);
    background-repeat: no-repeat;
    background-position: center center;
    transition: 0.2s;
  }

  .radio::before
  {
    border-radius: 50%;
  }

  .checkbox::before
  {
    border-radius: calc(var(--checkbox_size) * 0.2);
  }

  .radio:checked::before,
  .checkbox:checked::before
  {
    background-color: var(--checkbox_bg);
    border-color: var(--checkbox_border);
  }

  .radio:checked::before
  {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23ffffff'%3E%3C/circle%3E%3C/svg%3E");
    background-size: calc(var(--checkbox_size) * 0.4);
  }

  .checkbox:checked::before
  {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpolyline points='1 6.5 4 9.5 11 2.5' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
    background-size: calc(var(--checkbox_size) * 2 / 3);
  }

  input[type=date]::-webkit-inner-spin-button,
  input[type=date]::-webkit-clear-button,
  input[type=date]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button
  {
    -webkit-appearance: none;
  }

  .white_color
  {
    color: white;
  }

  .success_color
  {
    color: $success_color !important;
  }

  .error_color
  {
    color: $error_color !important;
  }

  .warn_color
  {
    color: $warn_color !important;
  }

  .minor_color
  {
    color: $info-color !important;
  }

  .info_color
  {
    color: var(--info_icon_bg, $info_color) !important;
  }

  .grey_color
  {
    color: $text_color;
  }

  .success_bg
  {
    background-color: $success_color;
  }

  .error_bg
  {
    background-color: $error_color;
  }

  .warn_bg
  {
    background-color: $warn_color;
  }

  .info_bg
  {
    background-color: $info_color;
  }

  .grey_bg
  {
    background-color: #D0D0D0;
  }

  .snotifyToast__body
  {
    word-break: break-word;
  }

  .file_input
  {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
  }

  iframe.frame_loaded
  {
    z-index: 1;
    background-color: #FFF;
  }

  .inset
  {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

</style>

<style lang="scss" src="@/assets/scss/common.scss"></style>

<style lang="scss" src="@/assets/scss/tooltip.scss"></style>

<style lang="scss" src="@/assets/scss/transitions.scss"></style>

<style lang="scss" src="@/assets/scss/snotify/material.scss"></style>
