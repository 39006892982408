<template>
  <form-field class="flex-auto">
    <input
      :value="value" :placeholder="$t('tables.search') + (wildcard ? ' ' + $t('tables.search_wildcard') : '')" style="min-width: 130px;"
      @input="$emit('input',$event.target.value)" @keydown.esc="$emit('input','')" @keydown.enter="$emit('search',false)"
    >
    <template slot="append">
      <dot-btn v-if="value" size="24" @click.native="$emit('search',true)">
        <my-icon class="ma-0">
          <icon-times />
        </my-icon>
      </dot-btn>
      <dot-btn size="24" :disabled="!value" @click="$emit('search',false)">
        <my-icon class="ma-0">
          <icon-search />
        </my-icon>
      </dot-btn>
    </template>
  </form-field>
</template>

<script>
import iconTimes from '@/assets/img/icon/times.svg';
import iconSearch from '@/assets/img/icon/search.svg';

export default
{
  name: 'TableSearch',
  components:
    {
      iconSearch,
      iconTimes,
    },
  props:
    {
      value:
        {
          type: String,
          default: ''
        },
      wildcard:
        {
          type: Boolean,
          default: true // eslint-disable-line vue/no-boolean-default
        }
    }
};
</script>
