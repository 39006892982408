<template>
  <pop-over boundaries-element="viewport" class="drop_down" popover-class="drop_down_popup" :offset="2" :popper-options="popperOptions" :open.sync="open" :placement="placement">
    <input v-if="open" ref="filter" v-model="search" class="full-width" :placeholder="currentValue" @keydown.esc.stop="open = false" @keydown.enter.stop="open = false">
    <input v-else :value="currentValue" class="full-width" readonly>
    <div slot="popover" style="max-width: 450px;">
      <template v-if="filteredItems.length > 0">
        <div v-for="(opt,idx) in filteredItems" :key="opt.id" v-close-popover :class="value === opt ? selectedClass : optionClass" class="pointer drop_down_option px-2 py-1" @click="$emit('input',opt)">
          <slot :item="opt" :index="idx">{{ opt }}</slot>
        </div>
      </template>
      <div v-else class="drop_down_option px-2 py-1" :class="optionClass">
        <slot name="empty" />
      </div>
    </div>
  </pop-over>
</template>

<script>
export default
{
  name: 'DropDown',
  props:
    {
      items:
        {
          type: Array,
          default: () => []
        },
      itemText:
        {
          type: [String, Function],
          default: 'text'
        },
      optionClass:
        {
          type: [String, Array, Object],
          default: ''
        },
      value:
        {
          type: Object,
          default: null
        },
      filter:
        {
          type: Function,
          default: null
        },
      placement:
        {
          type: String,
          default: 'bottom'
        },
      placeholder:
        {
          type: String,
          default: ''
        },
      expanded: // eslint-disable-line vue/no-unused-properties
        {
          type: Boolean,
          default: false
        }
    },
  data()
  {
    return {
      open: false,
      search: '',
    };
  },
  computed:
    {
      currentValue()
      {
        return (this.value ? (typeof this.itemText === 'string' ? (this.value || {})[this.itemText] : this.itemText(this.value || {})) || '' : '') || this.placeholder;
      },
      filteredItems()
      {
        const flt = this.search.toLowerCase();
        const textKey = this.itemText;
        const searchFilter = this.filter;
        return searchFilter
          ? this.items.filter(item =>
          {
            return searchFilter(item).toLowerCase().indexOf(flt) !== -1;
          })
          : typeof textKey === 'string'
            ? this.items.filter(item =>
            {
              return item[this.itemText].toLowerCase().indexOf(flt) !== -1;
            })
            : this.items.filter(item =>
            {
              return textKey(item).toLowerCase().indexOf(flt) !== -1;
            });
      },
      selectedClass()
      {
        switch (Object.toType(this.optionClass))
        {
          case 'string':
            return this.optionClass + ' selected';
          case 'array':
            return this.optionClass.concat('selected');
          case 'object':
            return Object.assign({ selected: true }, this.optionClass);
          default:
            return this.optionClass;
        }
      },
      popperOptions()
      {
        return {
          // positionFixed: true,
          modifiers:
            {
              flip:
                {
                  enabled: false
                },
              /*
              preventOverflow:
                {
                  enabled: false
                },
                */
              autoSizing:
                {
                  enabled: true,
                  order: 840,
                  fn(data)
                  {
                    const maxHeight = window.innerHeight - 4;
                    const maxWidth = window.innerWidth - 4;
                    if (/^top/.test(data.originalPlacement))
                    {
                      if (data.offsets.reference.top < data.offsets.popper.height)
                      {
                        // there is no room on top - try to flip if there is more room
                        if (maxHeight - data.offsets.reference.bottom > data.offsets.popper.height)
                        {
                          data.offsets.popper.top = Math.round(data.offsets.reference.bottom) + 2;
                          data.offsets.popper.height = Math.min(data.offsets.popper.height, maxHeight - Math.round(data.offsets.reference.bottom)) - 4;
                          data.placement = 'bottom';
                        }
                        else
                        {
                          // shrink to the available room on top
                          data.offsets.popper.top = 2;
                          data.offsets.popper.height = Math.min(data.offsets.popper.height, Math.round(data.offsets.reference.top)) - 4;
                          data.placement = 'top';
                        }
                        data.offsets.popper.bottom = data.offsets.popper.top + data.offsets.popper.height;
                        data.styles['max-height'] = data.offsets.popper.height + 'px';
                      }
                    }
                    else if (/^bottom/.test(data.originalPlacement))
                    {
                      if (maxHeight - data.offsets.reference.bottom < data.offsets.popper.height)
                      {
                        // there is no room on bottom - try to flip if there is more room
                        if (data.offsets.reference.top > data.offsets.popper.height)
                        {
                          data.offsets.popper.top = 2;
                          data.offsets.popper.height = Math.min(data.offsets.popper.height, Math.round(data.offsets.reference.top)) - 4;
                          data.placement = 'top';
                        }
                        else
                        {
                          // shrink to the available room on bottom
                          data.offsets.popper.top = Math.round(data.offsets.reference.bottom) + 2;
                          data.offsets.popper.height = Math.min(data.offsets.popper.height, maxHeight - Math.round(data.offsets.reference.bottom)) - 4;
                          data.placement = 'bottom';
                        }
                        data.offsets.popper.bottom = data.offsets.popper.top + data.offsets.popper.height;
                        data.styles['max-height'] = data.offsets.popper.height + 'px';
                      }
                    }
                    else if (/^left/.test(data.originalPlacement))
                    {
                      if (data.offsets.reference.left < data.offsets.popper.width)
                      {
                        // there is no room on left - try to flip if there is more room
                        if (maxWidth - data.offsets.reference.right > data.offsets.popper.width)
                        {
                          data.offsets.popper.left = Math.round(data.offsets.reference.right) + 2;
                          data.offsets.popper.width = Math.min(data.offsets.popper.width, maxWidth - Math.round(data.offsets.reference.right)) - 4;
                          data.placement = 'right';
                        }
                        else
                        {
                          // shrink to the available room on left
                          data.offsets.popper.left = 2;
                          data.offsets.popper.width = Math.min(data.offsets.popper.width, Math.round(data.offsets.reference.left)) - 4;
                          data.placement = 'left';
                        }
                        data.offsets.popper.right = data.offsets.popper.left + data.offsets.popper.width;
                        data.styles['max-width'] = data.offsets.popper.width + 'px';
                      }
                    }
                    else if (/^right/.test(data.originalPlacement))
                    {
                      if (maxWidth - data.offsets.reference.right < data.offsets.popper.width)
                      {
                        // there is no room on right - try to flip if there is more room
                        if (data.offsets.reference.left > data.offsets.popper.width)
                        {
                          data.offsets.popper.left = 2;
                          data.offsets.popper.width = Math.min(data.offsets.popper.width, Math.round(data.offsets.reference.left)) - 4;
                          data.placement = 'left';
                        }
                        else
                        {
                          // shrink to the available room on right
                          data.offsets.popper.left = Math.round(data.offsets.reference.right) + 2;
                          data.offsets.popper.width = Math.min(data.offsets.popper.width, maxWidth - Math.round(data.offsets.reference.right)) - 4;
                          data.placement = 'right';
                        }
                        data.offsets.popper.right = data.offsets.popper.left + data.offsets.popper.width;
                        data.styles['max-width'] = data.offsets.popper.width + 'px';
                      }
                    }
                    // data.styles.overflow = 'auto';
                    return data;
                  }
                }
            }
        };
      }
    },
  watch:
    {
      open(newVal)
      {
        this.$emit('update:expanded', newVal);
        if (newVal)
        {
          document.addEventListener('keydown', this.closeDropDown, false);
          this.openDropDown();
        }
        else
        {
          document.removeEventListener('keydown', this.closeDropDown, false);
        }
      },
      search(newVal)
      {
        this.$emit('search', newVal);
      }
    },
  methods:
    {
      openDropDown()
      {
        this.search = '';
        this.open = true;
        this.$nextTick(() =>
        {
          this.$refs.filter.focus();
        });
      },
      closeDropDown(event)
      {
        const e = event || window.event;
        if (this.open && e.keyCode === 27)
        {
          this.open = false;
          // All good browsers…
          if (e.preventDefault) e.preventDefault();
          // …and IE
          if (e.returnValue) e.returnValue = false;
          return false;
        }
        return true;
      }
    }
};
</script>

<style lang="scss">
  .drop_down.v-popover
  {
    display: flex;
    flex: 1 1 auto;
  }

  .drop_down .trigger
  {
    flex-grow: 1;
  }

  .drop_down_option.selected
  {
    background-color: #E0E0E0;
  }

  .drop_down_option:hover
  {
    background-color: $button_default_hover;
  }

  .drop_down_popup
  {
    border: 1px solid $dialog_border;
    border-radius: 4px;
    overflow: auto;
  }

  .drop_down_popup.tooltip.popover .popover-inner
  {
    border-radius: unset;
  }
</style>
