<template>
  <my-modal :value="value" @input="close">
    <my-card>
      <div slot="title" class="flexbox justify-between align-center">
        {{ $t('footer.account_history') }}
        <dot-btn class="secondary" @click="close">
          <my-icon>
            <icon-times />
          </my-icon>
        </dot-btn>
      </div>
      <div class="pa-3">
        <data-table v-loading="loading > 0" :header="headers" :items="logins">
          <template slot="items" slot-scope="row">
            <td>{{ row.item.user }}</td>
            <td>{{ row.item.type }}</td>
            <td>{{ row.item.ip }}</td>
            <td>{{ row.item.result }}</td>
            <td>{{ row.item.date }}</td>
          </template>
        </data-table>
      </div>
      <div slot="footer" class="pa-1 flexbox justify-center">
        <my-button class="secondary" @click="close">{{ $t('buttons.cancel') }}</my-button>
      </div>
    </my-card>
  </my-modal>
</template>

<script>
import iconTimes from '@/assets/img/icon/times.svg';
import dataTable from '@/components/ui/DataTable';

export default
{
  name: 'LoginHistory',
  components:
    {
      iconTimes,
      dataTable,
    },
  props:
    {
      value:
        {
          type: Boolean,
          default: false
        }
    },
  data()
  {
    return {
      loading: 0,
      logins: [],
    };
  },
  computed:
    {
      headers()
      {
        return [
          {
            text: this.$t('login.username'),
            align: 'left',
            value: 'name',
          },
          {
            text: this.$t('footer.history_type'),
            align: 'left',
            value: 'type',
          },
          {
            text: 'IP',
            align: 'center',
            value: 'ip',
          },
          {
            text: this.$t('footer.history_result'),
            align: 'left',
            value: 'result',
          },
          {
            text: this.$t('footer.history_date'),
            align: 'center',
            value: 'date',
          },
        ];
      }
    },
  watch:
    {
      value(newVal)
      {
        if (newVal) this.fetchData();
      }
    },
  methods:
    {
      close()
      {
        this.$emit('input', false);
      },
      fetchData()
      {
        this.$ajax(
          {
            method: 'GET',
            url: '/api/account/getloginhistory/',
            login: this.$root.login,
            okay: (data) =>
            {
              this.logins = data.data;
            },
            spinner: (show) =>
            {
              this.loading += show ? 1 : this.loading > 0 ? -1 : 0;
            }
          }
        );
      }
    }
};
</script>
