
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1179 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M1179 465q0 126-79 233T885 868t-296 62q-122 0-234-39l2 3L0 1001q44-59 71-126t31-106l4-38Q0 611 0 465q0-126 79-233T294 63 589 1t296 62 215 169 79 233zm-250 0q0-29-21-50t-51-21q-29 0-50 21t-21 50q0 30 21 51t50 21q30 0 51-21t21-51zm-250 0q0-29-21-50t-51-21q-29 0-50 21t-21 50q0 30 21 51t50 21q30 0 51-21t21-51zm-250 0q0-29-21-50t-51-21q-29 0-50 21t-21 50q0 30 21 51t50 21q30 0 51-21t21-51z"}})])
        )
      }
    }
  