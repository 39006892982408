<template>
  <my-modal :value="!!$root.twoFactor" :escape="false" persistent>
    <form v-loading="$root.spinFactor > 0" style="max-width: 460px;" @submit.prevent="doFactor">
      <my-card>
        <template slot="title">{{ $t('login.two_step') }}</template>
        <div class="pa-3">
          <info-box>
            <template v-if="($root.twoFactor || {}).secret">
              <div>{{ $t('login.two_step_help') }}</div>
              <div align="center" class="mt-2 mb-2">
                <a :href="otpURL">
                  <qr-code :value="otpURL" dot="5" />
                </a>
              </div>
              <form-field style="color: #000;">
                <div slot="prepend" class="pl-2 pr-2">{{ $t('login.two_step_secret') }}</div>
                <input :value="($root.twoFactor || {}).secret" readonly>
              </form-field>
            </template>
            <span v-else>{{ $t('login.two_step_info') }}</span>
          </info-box>
          <div class="flexbox flex-column align-center mt-3">
            <form-field>
              <div slot="prepend" class="px-2">{{ $t('login.two_step_code') }}</div>
              <input ref="code" v-model.trim="factorCode" minlength="6" maxlength="6" pattern="[0-9]+" style="width: 100px;" required>
            </form-field>
            <div v-if="factorFailed" class="center error_color pt-2">
              <my-icon class="mr-2">
                <icon-attention />
              </my-icon>
              {{ $t('login.two_step_fail') }}
            </div>
          </div>
          <div class="center pt-4">
            <a href="#" @click="dlgReset = true">{{ $t('login.two_step_reset') }}</a>
          </div>
        </div>
        <div slot="footer" class="pl-2 pr-2 pb-2 flexbox justify-center">
          <my-button class="primary" type="submit" :disabled="!factorCode">{{ $t('login.two_step_validate') }}</my-button>
          <my-button class="secondary" @click="logout">{{ $t('buttons.cancel') }}</my-button>
        </div>
      </my-card>
    </form>
    <reset-two-factor v-model="dlgReset" />
  </my-modal>
</template>

<script>
import infoBox from '@/components/widget/InfoBox';
import iconAttention from '@/assets/img/icon/attention.svg';
import qrCode from '@/components/ui/QRtable';
import resetTwoFactor from './TwoFactorReset';

export default
{
  name: 'TwoFactor',
  components:
    {
      infoBox,
      iconAttention,
      qrCode,
      resetTwoFactor,
    },
  data()
  {
    return {
      factorCode: '',
      factorFailed: '',
      dlgReset: false,
    };
  },
  computed:
    {
      otpURL()
      {
        const qr = (this.$root.twoFactor || {}).qr;
        return qr ? (qr.indexOf('googleapis.com') !== -1 ? decodeURIComponent(qr.replace(/^.+&chl=otpauth/, 'otpauth')) : qr) : '';
      }
    },
  watch:
    {
      '$root.twoFactor'(val)
      {
        if (val)
        {
          this.factorCode = '';
          this.$nextTick(() =>
          {
            this.$refs.code.focus();
          });
        }
        else this.factorFailed = '';
      }
    },
  created()
  {
    this.$root.$on('two-factor-error', this.factorError);
  },
  beforeDestroy()
  {
    this.$root.$off('two-factor-error', this.factorError);
  },
  methods:
    {
      doFactor()
      {
        if (!this.factorCode) return;
        this.$root.sendFactor(this.factorCode, this.$root.setLangCurrency);
      },
      factorError(msg)
      {
        this.factorFailed = msg;
      },
      logout()
      {
        this.$root.$emit('logout');
      },
    },
};
</script>
