<template>
  <main class="login_page">
    <form v-show="!$root.isLogged" ref="frm" v-loading="$root.spinUser > 0" class="login_form" @submit.prevent="doLogin">
      <my-card>
        <template slot="title">{{ $t('login.title') }}</template>
        <div slot="default" class="pa-3">
          <form-field :label="$t('login.username')">
            <my-icon slot="prepend" class="ml-2 mr-2">
              <icon-user />
            </my-icon>
            <input ref="user" v-model.trim="username" name="username" autocomplete="username" maxlength="80" required>
          </form-field>
          <form-field :label="$t('login.password')">
            <my-icon slot="prepend" class="ml-2 mr-2">
              <icon-lock />
            </my-icon>
            <input v-model.trim="password" name="password" autocomplete="current-password" maxlength="80" :type="showPassword ? 'text' : 'password'" required>
            <my-icon slot="append" class="ml-2 mr-2 pointer" @click.native="showPassword = !showPassword">
              <icon-visible v-if="showPassword" />
              <icon-invisible v-else />
            </my-icon>
          </form-field>
          <div class="mt-3 flexbox">
            <router-link :to="{name: 'forgotPassword'}" class="pr-5">{{ $t('login.forgot') }}</router-link>
            <router-link v-if="isDEV || $root.appISP" :to="{name: 'signUp'}" class="pl-5">{{ $t('login.register') }}</router-link>
          </div>
        </div>
        <div slot="footer" class="pl-2 pr-2 pb-2 flexbox justify-end">
          <my-button class="primary" type="submit" :disabled="!(username && password)">{{ $t('login.title') }}</my-button>
        </div>
      </my-card>
    </form>
  </main>
</template>

<script>
import iconUser from '@/assets/img/icon/user-solid.svg';
import iconLock from '@/assets/img/icon/lock.svg';
import iconVisible from '@/assets/img/icon/visibility.svg';
import iconInvisible from '@/assets/img/icon/visibility_off.svg';

export default
{
  name: 'LoginPage',
  components:
    {
      iconUser,
      iconLock,
      iconVisible,
      iconInvisible,
    },
  data()
  {
    return {
      showPassword: false,
      username: '',
      password: '',
    };
  },
  computed:
    {
      isDEV()
      {
        return process.env.NODE_ENV === 'development';
      },
    },
  watch:
    {
      '$root.isLogged'(newVal, oldVal)
      {
        if (oldVal && !newVal)
        {
          this.$nextTick(() =>
          {
            this.$refs.user.focus();
          });
        }
      }
    },
  created()
  {
    if (this.$root.isLogged) this.$router.push({ name: 'dashboard' }).catch(() => false);
    else this.$root.mustLogin = false;
  },
  mounted()
  {
    this.$refs.user.focus();
  },
  methods:
    {
      doLogin()
      {
        if (!(this.username && this.password)) return;
        this.$root.retry(this.username, this.password, this.$root.setLangCurrency);
      },
    }
};
</script>

<style>
  .login_page
  {
    display: flex;
  }

  .login_form
  {
    margin: auto;
    display: inline-block;
  }
</style>
