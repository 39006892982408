<template>
  <div>
    <flat-button v-show="$root.isLogged" v-loading="$root.spinUser > 0" class="dark ma-0 pr-0 font-2" @click="chooseAccount">
      {{ currentUserName }}
      <my-icon class="ml-2">
        <caret-down />
      </my-icon>
    </flat-button>
    <my-modal v-model="dlgAccounts" :escape="false" content-style="min-height: 50vh;">
      <my-card class="dialog_subaccounts">
        <div slot="title" class="flexbox justify-between align-center">
          <span>{{ $t('user.title') }}</span>
          <div class="flexbox">
            <my-button class="primary" @click="gotoSettings">
              <my-icon>
                <icon-gear />
              </my-icon>
              {{ $t('header.user_profile') }}
            </my-button>
            <my-button class="secondary" @click="dlgAccounts = false,$emit('exit')">
              {{ $t('header.logout') }}
            </my-button>
            <dot-btn class="secondary" @click="dlgAccounts = false">
              <my-icon class="mx-0">
                <!-- compensate margin from .main_menu .i_icon in Header.vue -->
                <icon-times />
              </my-icon>
            </dot-btn>
          </div>
        </div>
        <div class="pa-4">
          <div class="mb-4 infobox pa-4">
            <div class="pb-1">
              {{ $t('header.signed_as') }}:
            </div>
            <div>
              <strong>{{ $t('login.username') }}</strong>: {{ ($root.user || {}).username }}
              <strong>{{ $t('signup.organization') }}</strong>: {{ (($root.account || {}).contact || {}).organization }}
              <strong>{{ $t('signup.id_number') }}</strong>: {{ (($root.account || {}).contact || {}).orgnr }}
            </div>
          </div>
          <template v-if="subAccounts.length > 0">
            <div class="card_title pl-0 mb-2">{{ $t('header.choose_account') }}</div>
            <data-filter v-model="search" :wildcard="false" @search="updateSearch" />

            <data-table :header="accHeaders" :items="accList" class="mt-3 tbl_subaccounts" rows>
              <template #rows="items">
                <tr v-for="(item,idx) in items.row" :key="idx" :class="{pointer: !item.active}" @click="changeAccount(item.id)">
                  <td>{{ item.fullname }}</td>
                  <td>{{ item.organization }}</td>
                  <td>{{ item.orgnr }}</td>
                </tr>
              </template>
            </data-table>
          </template>
        </div>
        <!--
        <div slot="footer" class="flexbox justify-center pl-2 pr-2 pb-2">
          <my-button class="secondary" @click="dlgAccounts = false">{{ $t('buttons.close') }}</my-button>
        </div>
        -->
      </my-card>
    </my-modal>
  </div>
</template>

<script>
import caretDown from '@/assets/img/icon/caret-down.svg';
import dataTable from '@/components/ui/DataTable';
import dataFilter from '@/components/TableSearch';
import iconGear from '@/assets/img/icon/gear.svg';
import iconTimes from '@/assets/img/icon/times.svg';

export default
{
  name: 'HeaderAccount',
  components:
    {
      dataTable,
      dataFilter,
      caretDown,
      iconGear,
      iconTimes,
    },
  data()
  {
    return {
      dlgAccounts: false,
      search: '',
    };
  },
  computed:
    {
      currentUserName()
      {
        const user = (this.$root.account || {}).contact || {};
        return user ? this.$t('login.welcome') + ' ' + ((this.$root.user || {}).username || '') + ' (' + (user.organization || [user.firstname || '', user.lastname || ''].join(' ').trim()) + ')' : '';
      },
      masterAccount()
      {
        const accounts = this.$root.user.accounts;
        return accounts ? accounts.masteraccount[0] : {};
      },
      subAccounts()
      {
        const accounts = this.$root.user.accounts;
        return accounts ? accounts.subaccounts : [];
      },
      accHeaders()
      {
        return [
          {
            text: this.$t('login.username'),
            align: 'left',
            value: 'fullname',
          },
          {
            text: this.$t('signup.organization'),
            align: 'left',
            value: 'organization',
          },
          {
            text: this.$t('signup.id_number'),
            align: 'left',
            value: 'orgnr'
          },
        ];
      },
      accList()
      {
        const curID = this.$root.session.accID;
        const filter = this.search.toLowerCase();
        const result = [{
          master: true,
          id: this.masterAccount.usrAccID,
          active: curID == this.masterAccount.usrAccID,
          fullname: this.masterAccount.fullname,
          _fullname: (this.masterAccount.fullname || '').toLowerCase(),
          organization: this.masterAccount.organization,
          _organization: (this.masterAccount.organization || '').toLowerCase(),
          orgnr: this.masterAccount.orgnr || '',
        }].concat(this.subAccounts.map(acc =>
          ({
            master: false,
            id: acc.usrAccID,
            active: curID == acc.usrAccID,
            fullname: acc.fullname,
            _fullname: (acc.fullname || '').toLowerCase(),
            organization: acc.organization,
            _organization: (acc.organization || '').toLowerCase(),
            orgnr: acc.orgnr || '',
          }))).filter(acc => acc.id !== curID);
        return filter
          ? result.filter(acc =>
          {
            return acc._fullname.indexOf(filter) !== -1 || acc._organization.indexOf(filter) !== -1 || acc.orgnr.indexOf(filter) !== -1;
          })
          : result;
      },
    },
  methods:
    {
      changeAccount(id)
      {
        if (this.subAccounts.length === 0 || this.$root.accID == id) return;
        this.dlgAccounts = false;
        this.$ajax(
          {
            method: 'GET',
            url: '/api/user/selectaccount/?accid=' + id,
            login: this.$root.login,
            okay: (data) =>
            {
              this.$root.getUserAccount(() =>
              {
                this.$root.refresh++;
              });
            },
            spinner: (show) =>
            {
              this.$root.spinUser += show ? 1 : this.$root.spinUser > 0 ? -1 : 0;
            }
          }
        );
      },
      chooseAccount()
      {
        this.dlgAccounts = true;
      },
      updateSearch(clear)
      {
        if (clear) this.search = '';
      },
      gotoSettings()
      {
        this.dlgAccounts = false;
        if (this.$route.name !== 'userSettings') this.$router.push({ name: 'userSettings' }).catch(() => true);
      }
    }
};
</script>

<style lang="scss">
  @import '@/assets/scss/theme/header.scss';

  .popover_header.card_title
  {
    background-color: $account_title;
    color: text-contrast($account_title);
    font-size: 1.2rem;
  }

  .dialog_subaccounts
  {
    color: $text_color;
    min-width: 50vw;
  }
  /*
  .tbl_subaccounts tbody td:nth-child(2)
  {
    text-transform: uppercase;
  }
  */
</style>
