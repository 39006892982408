<template>
  <div class="justify-between align-center" :class="{flexbox: !inline, 'inline-flex': inline}">
    <label v-if="right" :for="'_sw_' + _uid" :class="{pointer: !disabled}" class="mr-2">{{ label }}</label>
    <div class="switch" :class="{checked: value,pointer: !disabled}" @click="doToggle">
      <input :id="'_sw_' + _uid" v-model="current" type="checkbox" :disabled="disabled">
      <div class="switch_thumb" />
    </div>
    <label v-if="!right" :for="'_sw_' + _uid" :class="{pointer: !disabled}" class="ml-2">{{ label }}</label>
  </div>
</template>

<script>
export default
{
  name: 'SwitchComp',
  props:
    {
      right:
        {
          // label on the left, switch on the right
          type: Boolean,
          default: false
        },
      value:
        {
          type: Boolean,
          default: false
        },
      label:
        {
          type: String,
          default: ''
        },
      inline:
        {
          type: Boolean,
          default: true // eslint-disable-line vue/no-boolean-default
        },
      disabled:
        {
          type: Boolean,
          default: false
        }
    },
  data()
  {
    return {
      current: this.value,
    };
  },
  watch:
    {
      value(newVal, oldVal)
      {
        if (newVal !== oldVal) this.current = newVal;
      }
    },
  methods:
    {
      doToggle()
      {
        if (!this.disabled)
        {
          this.$emit('input', !this.value);
          this.$emit('change', !this.value);
        }
      }
    }
};
</script>

<style lang="scss">
  $switch_height: 16px;
  $switch_width: 2.5 * $switch_height;

  .switch
  {
    position: relative;
    width: $switch_width;
    height: $switch_height + 4px;
    border-radius: $switch_height;
    border: 1px solid var(--toggle_off, $switch_off);
    transition: background-color 0.2s linear;
    background-color: white;
  }

  .switch.checked
  {
    border: none; /* Fix for bug in Firefox 81/82, and probably later */
    border-color: var(--toggle_on, $switch_on);
    background-color: var(--toggle_bg, $switch_bg);
  }

  .switch_thumb
  {
    position: absolute;
    border-radius: 50%;
    top: calc(50% - #{$switch_height / 2});
    left: 1px;
    height: $switch_height;
    width: $switch_height;
    background-color: var(--toggle_off, $switch_off);
    transition: transform 0.15s linear;
  }

  .switch.checked .switch_thumb
  {
    transform: translateX($switch_width - $switch_height - 4px);
    background-color: var(--toggle_on, $switch_on);
  }

  .switch > input
  {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    position: absolute;
  }
</style>
