
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 900 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M502 850V750h98v100q0 40-29 70t-71 30H100q-40 0-70-30T0 850V150q0-42 30-71t70-29h400q42 0 71 29t29 71v150h-98V150H100v700h402zm398-326L702 720V600H252V450h450V330z"}})])
        )
      }
    }
  